import React from "react";
import Button from "@common/Button/Button";
import { MAIN_BUTTON } from "@constants";
import chatIcon from "@images/ic-chat.svg";
import {
    Container,
    ButtonContainer,
    ContentContainer,
    Title,
} from "./CallToActionSection.style";

const CallToActionSection = ({ text, buttonText, buttonNavigation }) => (
    <Container>
        <ContentContainer>
            <Title>{text}</Title>
            <ButtonContainer href={buttonNavigation}>
                <Button
                    text={buttonText}
                    type={MAIN_BUTTON}
                    bannerButtonIconPath={chatIcon}
                />
            </ButtonContainer>
        </ContentContainer>
    </Container>
);

export default CallToActionSection;
